.logo1 {
    width: 100px;
    margin-left: 50px;
    

}

/* .fot {
    background-color: grey;
    height: 70px;
} */
