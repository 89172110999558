.icon {
  height: 20px;
  width: auto;
  margin-right: 5px;
}

.icon2 {
  height: 25px;
  width: auto;
  margin-right: 5px;
}

.phone {
  margin-right: 25px
}

.contacto {
  height: 30px;
  position: absolute;
  right: 40px;
  display: flex;
  flex-flow: row wrap;
  margin-right: 33px;
  margin-top: 10px;
  font-family: "Roboto";
  font-size: 15px;
  z-index: 999;
}

.info {
  margin-bottom: 50px;
}

.contact {
  font-family: "Roboto";
  font-size: 35px;    
}

.email {
    text-decoration: none;
    color: black;
}

.header {
  font-family: "Roboto";
  font-weight: 300px;
  font-size: 45px;
  margin-bottom: 50px;
  border-radius: 50px;
}

.header1 {
  font-family: "Roboto";
  font-weight: 300px;
  font-size: 35px;
  font-style: italic;
}

.headline {
  font-family: "Roboto";
  font-weight: 300px;
  font-size: 25px;
}

/* beneficios */
.headline1 {
  font-family: "Roboto";
  font-weight: 300px;
  font-size: 20px;
  font-style: italic;
  margin-bottom: 40px;
}

.text {
  font-family: "Roboto";
  font-size: 15px;
  /* text-align: left; */
  color: black;
  font-weight: lighter !important;
  margin-bottom: 50px;
  columns: 2;
  column-gap: 50px;
}


/* beneficios */
.text1 {
  font-family: "Roboto";
  font-size: 15px;
  /* text-align: left; */
  color: black;

}

.special {
  font-family: "Roboto";
  font-size: 20px;
}

.gallery {
  margin-bottom: 50px;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.read-more-link{
  color: blueviolet;
  text-decoration: underline;
  letter-spacing: 1px;
  cursor: pointer;
}
.extra-content{
  color: cornflowerblue;
  font-weight: 500;
}

@media screen and (max-width: 900px) {
	.picture {   
    height: 200px !important;
    background-position-y: 0px !important;
	}

  .contacto {   
    width: 100% !important;
    right: 0px;
    text-align: right;
    padding-right: 0px;
    padding-left: 5px;
    margin-right: 0px !important;
	}

  .logo {
    width: 90px !important;
    margin-top: 30px;
    margin-bottom: 10px;
  }

  .text {
    columns: 1 !important;
  }

  .cardbody1 {
    margin-left: 0px !important;
  }

  .icon, .icon2 {
    height: 18px;
    margin-right: 2px;
  }

  .navbar-toggler {
    margin-right: 15px;
  }

  .ms-auto {
    text-align: center;
    margin-right: 0px !important;
  }

  .header {
    font-size: 2rem !important;
  }
}