/* .text {
    margin-right: 250px;
    /* margin-left: 250px; */
    /* font-size: 15px;
    text-align: center;
    color: black; */
    /* text-shadow: 2px 2px whitesmoke; */
/* } */ */

.speciell {
    font-size: 20px;
}