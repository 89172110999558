body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

/* background-image: url(../src/components/pictures/sunset_NY.jpg) ; */
/* background-image: url(../src/components/pictures/pose_NY.jpg) ; */
/* background-color: rgb(240, 226, 196) !important; */
/* background-image: url(../src/components/pictures/sea.jpg) ; */
/* background-image: url(../src/components/pictures/stone.jpg) ; */
  /* background-image: url(../src/components/pictures/stone_bw.png) ; */
/* background-image: url(../src/components/pictures/stone_color_new.png) ; */
/* background-image: url(../src/components/pictures/stone_stor.jpg) ; */
/* background-image: url(../src/components/pictures/stone_liten.jpg) ; */
/* background-image: url(../src/components/pictures/stone_stor.png) ; */
/* background-image: url(../src/components/pictures/flower.png) ; */
/* background-image: url(../src/components/pictures/flower_color.png) ; */
  /* background-image: url(../src/components/pictures/flower_bw.png) ; */
background-size: cover;

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

