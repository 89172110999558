.picture {
    background-image: url("../pictures/dog.jpg");
    /* padding-top: 30px; */
    /* width: 100%; */
    height: 650px;
    /* height: auto; */
    display: flex;
    justify-content: center;
    background-size: cover;
    background-repeat: no-repeat;
    /* background-position: bottom; */
    background-position-y: -500px;
    box-shadow: 5px 5px 15px grey;
    margin-bottom: 50px;
}

.cardbody {
    border: none !important;
    margin-bottom: 50px;
}

.cardbody1 {
    border: none !important;
    margin-bottom: 50px;
    margin-left: 20px;
}

.cardtitle {
    font-family: "Roboto";
    text-align: left;

  }
  
  .cardtext {
    font-family: "Roboto";
    text-align: left;
  }