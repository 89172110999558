.picture1 {
    height: 250px;
    margin-bottom:50px;
    margin-top: 30px;
    margin-right: 25px;
    margin-left: 25px;
    /* box-shadow: 5px 5px 5px rgb(94, 92, 92); */
    /* border-radius: 50px; */
    border-radius: 20px;
    text-align: center;
    padding: 10px;
    
}

.picture2 {
    height: 100px;
    margin-bottom:50px;
    margin-top: 30px;
    margin-right: 25px;
    margin-left: 25px;
    /* box-shadow: 5px 5px 5px rgb(94, 92, 92); */
    /* border-radius: 50px; */
    border-radius: 20px;
    text-align: center;
    padding: 10px;

}

.gallery {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    
}


/* .header2 {
    font-family: "Neuton";
    font-weight: 300px;
    font-size: 45px;
    margin-bottom: 10px;
    margin-top: 50px !important;
    margin-left: 50px;
} */


