.logo {
    width: 130px;
    margin-left: 50px;
}


.nav-link {
    color: black !important;
    font-family: "Roboto";
    font-size: 20px;
    margin-right: 15px;

}

.ms-auto {
    margin-right: 50px;

}

.drops {
    font-family: "Roboto";
    font-size: 17px;
    text-decoration: none !important;
    color: black;
}

.dropdown-menu {
    background-image: url("../pictures/pose_NY.jpg");
    border: none !important;
}

.bakgrund {
    border-radius: 50px;
}
